import React, { useState } from 'react';
import { graphql, navigate } from 'gatsby';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import Button from '../../components/Button';
import Image from '../../components/Image';
import InputText from '../../components/InputText';
import ListItem from '../../components/ListItem';
import './style.sass';

export default props => {
  const [inputValue, setInputValue] = useState('');
  const fortunes = props.data.allFortuneCsv.edges;
  const selects = props.data.allSelectedCsv.edges;

  const searchKeywords = !props.location.state
    ? []
    : props.location.state.keywords;

  // const results = fortunes.filter(fortune => {
  //   return (
  //     fortune.node.keywords.split(',').filter(k => {
  //       return (
  //         searchKeywords.filter(searchKeyword => {
  //           return k.replace(/\s+/g, '') === searchKeyword;
  //         }).length > 0
  //       );
  //     }).length > 0
  //   );
  // });

  const results = fortunes.filter(fortune => {
    return (
      fortune.node.keywords.split(',').filter(k => {
        return (
          searchKeywords.filter(searchKeyword => {
            return searchKeyword.indexOf(k.replace(/\s+/g, '')) === 0;
          }).length > 0
        );
      }).length > 0
    );
  });

  const handleChange = e => setInputValue(e.currentTarget.value);

  const handleSearchButtonClick = e => {
    if (inputValue === '') {
      return;
    }

    navigate(`/search`, { state: { keywords: inputValue.split(' ') } });
  };

  const notFoundTemplate = () => {
    return (
      <>
        <figure className="fortune__figure">
          <Image filename="img_pien.png" />
        </figure>
        <div className="search__notFound">
          ごめんなさい...
          <br />
          その夢は今は答えられないわ
          <br />
          また今度調べておくわね！
        </div>
      </>
    );
  };

  return (
    <Layout>
      <Seo
        title="マダムたぬきとは"
        description="マダムたぬきについてのページです"
        href={props.location.href}
      />

      {results.length > 0 ? (
        <>
          <figure className="fortune__figure">
            <Image filename="img_normal.png" />
          </figure>
          <div className="indexSearch__message">
            あなたの調べたい夢はこの中にあるかしら？
          </div>
          <div className="linkList">
            {results.map((item, i) => {
              const targets = selects.filter(
                select => item.node.id === select.node.parentId
              );
              return targets.map((target, i) => {
                return (
                  <ListItem
                    key={i}
                    to={`/selected/${target.node.id}`}
                    type="wide"
                  >
                    <div className="listItem__header">{target.node.title}</div>
                    <div className="listItem__body">
                      {target.node.description}
                    </div>
                  </ListItem>
                );
              });
            })}
          </div>
        </>
      ) : (
        notFoundTemplate()
      )}
      <div className="searchForm">
        <div className="searchForm__message">他のキーワードで調べる</div>
        <div className="searchForm__inputText">
          <InputText
            name="keyword"
            handleChange={handleChange}
            placeholder="キーワードを入力してください"
          />
        </div>
        <div className="searchForm__Button">
          <Button label="検索" handleClick={handleSearchButtonClick} />
        </div>
      </div>
      <div className="search__buttonWrapper">
        <Button label="トップへ戻る" to={`/`} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query searchFortuneCsv {
    allFortuneCsv {
      edges {
        node {
          id
          title
          description
          keywords
        }
      }
    }
    allSelectedCsv {
      edges {
        node {
          id
          title
          description
          body
          score
          parentId
        }
      }
    }
  }
`;
